<template>
  <div v-if="items.length > 0">
    <l-map
      ref="map"
      style="height: 350px"
      :zoom="zoom"
      :center="mapCenter"
      :bounds="bounds"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <template v-for="(point, i) in markers">
        <l-marker
          :key="i"
          :lat-lng="point"
          @click="showPopupMarkers(i)"
        >
          <l-popup>
            <div class="">
              {{ $t("name") }}:
              <span>{{ popupDetail?.name }}</span>
            </div>
            <div class="">
              {{ $t("contact") }}:
              <span>{{
                `${popupDetail?.country_code}-${popupDetail?.phone_number}`
              }}</span>
            </div>
            <div class="">
              {{ $t("tenant_name") }}:
              <span>{{ popupDetail?.tenant_name }}</span>
            </div>
            <div class="">
              {{ $t("timings") }}:
              <span>{{ popupDetail?.timings }}</span>
            </div>
            <div class="">
              {{ $t("status") }}:
              <span>{{ popupDetail?.location_status }}</span>
            </div>
          </l-popup>
        </l-marker>
      </template>
    </l-map>
  </div>
</template>

<script>
import { latLngBounds } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { mapGetters } from "vuex";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 16,
      //   markers: [
      //     [24.9008, 67.1681],
      //     [24.88245, 67.069389],
      //   ],
      popupDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
    markers() {
      let arr = [];
      arr = this.items.map((r) => [r.latitude, r.longitude]);
      return arr;
    },
    mapCenter() {
      return this.markers[0];
    },
    bounds() {
      return latLngBounds(this.markers);
    },
  },

  methods: {
    showPopupMarkers(index) {
      this.popupDetail = this.items[index];
      // Get the coordinates of the clicked point
      //   const latlng = event.latlng;
      //   const detail = this.tripsData.find(
      //     (r) =>
      //       r.position.latitude == latlng.lat &&
      //       r.position.longitude == latlng.lng
      //   );
      //   // Set the popup coordinates and content
      //   this.popupLatLng = { ...latlng, ...detail };
    },
  },
};
</script>
